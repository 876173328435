<template>
  <div data-app>
    <!-- Countries start -->
    <div class="row mx-0 mr-3 pt-5 mb-5">
      <div class="col-12 row mx-0 p-1 bg-white">
        <div class="col-2">Navigate to sections:</div>
        <div class="col-10 p-0 navigation_mis_ch">
          <a href="#countries_id">
            <span class="v-btn__content">Countries</span>
          </a>
          <a href="#States_id">
            <span class="v-btn__content">States</span>
          </a>
          <a href="#cities_id">
            <span class="v-btn__content">cities</span>
          </a>
          <a href="#gender_id">
            <span class="v-btn__content">Gender</span>
          </a>
          <a href="#Industry_id">
            <span class="v-btn__content">Industry</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row mx-0 pt-3" id="countries_id">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Manage countries
          </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable1"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new
            </span>
          </button>
        </div>
        <v-data-table
          :headers="headers1"
          :items="data1"
          item-key="country_name"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
          :loading="loading1"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.country_name }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeCountryStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick1(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item, 'country')"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm1"
            class="card-label font-weight-bolder Main_Blue"
            >Add new country
          </span>
          <span
            v-if="updateForm1"
            class="card-label font-weight-bolder Main_Blue"
            >Update country
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form
            ref="form"
            v-if="createForm1"
            v-model="valid1"
            lazy-validation
          >
            <v-text-field
              v-model="country"
              :rules="nameRules1"
              label="Name"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createCountry"
              :disabled="country === '' ? true : false"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form
            ref="form"
            v-if="updateForm1"
            v-model="valid1"
            lazy-validation
          >
            <v-text-field
              v-model="country"
              :rules="nameRules1"
              label="Name"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateCountry"
              :disabled="country === '' ? true : false"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel1"
            >
              Cancel
            </v-btn>
          </v-form>
        </div>
        <!-- update form end -->
      </div>
    </div>
    <!-- Countries end -->

    <!-- States start -->
    <div class="row mx-0" id="States_id" style="margin-top: 2%">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >States/provinces
          </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable2"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers2"
          :items="data2"
          item-key="name"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
          :loading="loading2"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.state_name }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.country_name }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStateStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick2(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item, 'state')"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm2"
            class="card-label font-weight-bolder Main_Blue"
            >Add new State
          </span>
          <span
            v-if="updateForm2"
            class="card-label font-weight-bolder Main_Blue"
            >Update State
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form
            ref="form"
            v-if="createForm2"
            v-model="valid2"
            lazy-validation
          >
            <v-text-field
              v-model="state"
              :rules="nameRules2"
              label="State name"
              required
            ></v-text-field>
            <b-form-select
              v-model="country_id"
              :options="countriesOptions"
              size="sm"
              class="mt-3 rounded-0"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="createState"
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Save</span
                >
              </button>
            </div>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form
            ref="form"
            v-if="updateForm2"
            v-model="valid2"
            lazy-validation
          >
            <v-text-field
              v-model="state"
              :rules="nameRules2"
              label="State name"
              required
            ></v-text-field>
            <b-form-select
              v-model="country_id"
              :options="countriesOptions"
              size="sm"
              class="mt-3 rounded-0"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="updateState"
                :disabled="
                  (country_id === '' || country_id === null) && state === ''
                    ? true
                    : false
                "
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Update</span
                >
              </button>
              <v-btn
                style="padding: 8px !important"
                class="custom-add-new-record-button btn_red__ch"
                @click="cancel2"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
        <!-- update form end -->
      </div>
    </div>
    <!-- States end -->

    <!-- Cities start -->
    <div class="row mx-0" id="cities_id" style="margin-top: 2%">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Manage cities
          </span>
        </div>
        <div
          class="col-4 p-0 pt-3 text-end border_b_silver bg-white"
          style="position: relative"
        >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            class="mt-0 pt-0"
            single-line
            hide-details
          ></v-text-field>
          <img
            src="\images/nav_icon/serch1.svg"
            alt=""
            height="16px"
            width="16px"
            class="img_search_icon"
          />
        </div>
        <div class="col-2 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable3"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-card class="rounded-0">
          <v-data-table
            :headers="headers3"
            :items="data3"
            :search="search"
            item-key="name"
            class="elevation-1 px-4 rounded-0 headers_job_function_ch"
            :loading="loading3"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item="props">
              <tr class="border__ch rounded-0">
                <td
                  class="Main_Blue"
                  style="font-size: 14px; font-weight: 500 !important"
                >
                  {{ props.item.city_name }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.state_name }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 12px; font-weight: 500 !important"
                >
                  {{ props.item.country_name }}
                </td>
                <td>
                  <toggle-button
                    :value="props.item.status == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeCityStatus(props.item)"
                  />
                  {{ props.item.status ? "Activated" : "Deactivated" }}
                </td>
                <td class="border-0 d-flex pt-3" style="">
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="editButtonClick3(props.item)"
                  >
                    <img
                      src="\images/job_application/Edit_Icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="askUserAboutDelete(props.item, 'city')"
                  >
                    <img
                      src="\images/job_application/Delete.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td colspan="12" style="height: 12px"></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm3"
            class="card-label font-weight-bolder Main_Blue"
            >Add new City
          </span>
          <span
            v-if="updateForm3"
            class="card-label font-weight-bolder Main_Blue"
            >Update City
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form
            ref="form"
            v-if="createForm3"
            v-model="valid3"
            lazy-validation
          >
            <v-text-field
              v-model="city"
              :rules="nameRules3"
              label="City name"
              required
            ></v-text-field>
            <b-form-select
              v-model="state_id"
              :options="statesOptions"
              size="sm"
              class="mt-3"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="createCity"
                :disabled="
                  (state_id === '' || state_id === null) && city === ''
                    ? true
                    : false
                "
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Save</span
                >
              </button>
            </div>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form
            ref="form"
            v-if="updateForm3"
            v-model="valid3"
            lazy-validation
          >
            <v-text-field
              v-model="city"
              :rules="nameRules3"
              label="City name"
              required
            ></v-text-field>
            <b-form-select
              v-model="state_id"
              :options="statesOptions"
              size="sm"
              class="mt-3"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="updateCity"
                :disabled="
                  (state_id === '' || state_id === null) && city === ''
                    ? true
                    : false
                "
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Update</span
                >
              </button>
              <v-btn
                style="padding: 8px !important"
                class="custom-add-new-record-button btn_red__ch"
                @click="cancel3"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Cities end -->

    <!-- Gender start -->
    <div class="row mx-0 pt-3" id="gender_id">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Gender </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable4"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers4"
          :items="data4"
          item-key="name"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.gender_name }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeGanderStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick4(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item, 'gender')"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm4"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Gender
          </span>
          <span
            v-if="updateForm4"
            class="card-label font-weight-bolder Main_Blue"
            >Update Gender
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form
            ref="form"
            v-if="createForm4"
            v-model="valid4"
            lazy-validation
          >
            <v-text-field
              v-model="gender"
              :rules="nameRules4"
              label="Gender"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createGender"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form
            ref="form"
            v-if="updateForm4"
            v-model="valid4"
            lazy-validation
          >
            <v-text-field
              v-model="gender"
              :rules="nameRules4"
              label="Gender"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateGender"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Gender End -->

    <!-- Industry start -->
    <div class="row mx-0 pt-3" id="Industry_id">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Industry </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable5"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers5"
          :items="data5"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.industry_name }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus5(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick5(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item, 'industry')"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm5"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Industry
          </span>
          <span
            v-if="updateForm5"
            class="card-label font-weight-bolder Main_Blue"
            >Update Industry
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <!-- create form start -->
          <v-form
            ref="form"
            v-if="createForm5"
            v-model="valid5"
            lazy-validation
          >
            <v-text-field
              v-model="industry"
              :rules="nameRules5"
              label="Industry"
              required
            ></v-text-field>
            <button
              type="button"
              @click="createIndustry"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form
            ref="form"
            v-if="updateForm5"
            v-model="valid5"
            lazy-validation
          >
            <v-text-field
              v-model="industry"
              :rules="nameRules5"
              label="Industry"
              required
            ></v-text-field>
            <button
              type="button"
              @click="updateIndustry"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel5"
            >
              Cancel
            </v-btn>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Industry end -->

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import LocationService from "@/MainServices/LocationService.js";
import GenderService from "@/MainServices/GenderService.js";
import IndustryService from "@/MainServices/IndustryService.js";

export default {
  data() {
    return {
      dialog: false,
      deleteId: null,
      deleteType: null,
      deleting: false,
      loading1: true,
      success1: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid1: true,
      createForm1: true,
      updateForm1: false,
      country: "",
      nameRules1: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers1: [
        { text: "Country", value: "country_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data1: [],

      // states
      loading2: true,
      success2: false,
      valid2: true,
      createForm2: true,
      updateForm2: false,
      state: "",
      state_id: null,
      country_id: null,
      nameRules2: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers2: [
        { text: "State", value: "state_name" },
        { text: "Country", value: "country_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data2: [],
      countriesOptions: [{ value: null, text: "Please select country" }],
      countries: [],

      // cities
      search: "",
      loading3: true,
      success3: false,
      valid3: true,
      createForm3: true,
      updateForm3: false,
      city: "",
      city_id: null,
      nameRules3: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers3: [
        { text: "City", value: "city_name" },
        { text: "State", value: "state_name" },
        { text: "Country", value: "country_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data3: [],
      statesOptions: [{ value: null, text: "Please select state" }],
      states: [],

      // GENDER
      loading4: true,
      success4: false,
      valid4: true,
      createForm4: true,
      updateForm4: false,
      gender: "",
      nameRules4: [
        (v) => !!v || "Gender is required",
        (v) =>
          (v && v.length <= 10) || "Gender must be less than 10 characters",
      ],
      headers4: [
        { text: "Gender", value: "gender_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data4: [],

      // Industry

      loading5: true,
      success5: false,
      valid5: true,
      createForm5: true,
      updateForm5: false,
      industry: "",
      nameRules5: [
        (v) => !!v || "Industry is required",
        (v) =>
          (v && v.length <= 10) || "Industry must be less than 10 characters",
      ],
      headers5: [
        { text: "Industry", value: "industry_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data5: [],
    };
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    getInititalData() {
      LocationService.getCountries()
        .then((res) => {
          this.data1 = res.data;
          this.loading1 = false;
          res.data.forEach((element) => {
            this.countriesOptions.push({
              value: element.id,
              text: element.country_name,
            });
          });
        })
        .catch(() => {
          this.loading1 = false;
        });

      LocationService.getStates()
        .then((res) => {
          this.data2 = res.data;
          this.loading2 = false;
          res.data.forEach((element) => {
            this.statesOptions.push({
              value: element.id,
              text: element.state_name,
            });
          });
        })
        .catch(() => {
          this.loading2 = false;
        });

      LocationService.getCities()
        .then((res) => {
          this.data3 = res.data;
          this.loading3 = false;
        })
        .catch(() => {
          this.loading3 = false;
        });
      GenderService.getGenders()
        .then((res) => {
          this.data4 = res.data;
          this.loading4 = false;
        })
        .catch(() => {
          this.loading4 = false;
        });
      IndustryService.getIndustries()
        .then((res) => {
          this.data5 = res.data;
          this.loading5 = false;
        })
        .catch(() => {
          this.loading5 = false;
        });
    },
    cancel1() {
      this.createForm1 = true;
      this.updateForm1 = false;
      this.country = "";
    },
    createFormEnable1() {
      this.country = "";
      this.createForm1 = true;
      this.updateForm1 = false;
    },

    createCountry() {
      if (this.country == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/countries`, {
          country_name: this.country,
        })
        .then((res) => {
          this.loading1 = false;
          this.success1 = true;
          this.country = "";
          this.createForm1 = true;
          this.updateForm1 = false;
          Swal.fire({
            title: "",
            text: "Country added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCountries()
            .then((res) => {
              this.data1 = res.data;
              this.loading1 = false;
              res.data.forEach((element) => {
                this.countriesOptions.push({
                  value: element.id,
                  text: element.country_name,
                });
              });
            })
            .catch(() => {
              this.loading1 = false;
            });
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateCountry() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/countries/${this.country_id}`, {
          country_name: this.country,
        })
        .then((res) => {
          this.loading1 = false;
          this.success1 = true;
          this.country = "";
          this.country_id = "";
          Swal.fire({
            title: "",
            text: "Country updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCountries()
            .then((res) => {
              this.data1 = res.data;
              this.loading1 = false;
              res.data.forEach((element) => {
                this.countriesOptions.push({
                  value: element.id,
                  text: element.country_name,
                });
              });
            })
            .catch(() => {
              this.loading1 = false;
            });
          this.cancel1();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeCountryStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/countries/${item.id}`, {
          country_name: item.country_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading1 = false;
          this.success1 = true;
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCountries()
            .then((res) => {
              this.data1 = res.data;
              this.loading1 = false;
              res.data.forEach((element) => {
                this.countriesOptions.push({
                  value: element.id,
                  text: element.country_name,
                });
              });
            })
            .catch(() => {
              this.loading1 = false;
            });
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    editButtonClick1(item) {
      this.createForm1 = false;
      this.updateForm1 = true;
      this.country = item.country_name;
      this.country_id = item.id;
    },

    deleteButtonClick1(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/countries/${item}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Country deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCountries()
            .then((res) => {
              this.data1 = res.data;
              res.data.forEach((element) => {
                this.countriesOptions.push({
                  value: element.id,
                  text: element.country_name,
                });
              });
            })
            .catch(() => {
              this.loading1 = false;
            });
          this.loading1 = false;
          this.dialog = false;
          this.deleteId = null;
          this.deleteType = null;
          this.deleting = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading1 = false;
          this.dialog = false;
          this.deleteId = null;
          this.deleteType = null;
          this.deleting = false;
        });
    },

    // states
    cancel2() {
      this.createForm2 = true;
      this.updateForm2 = false;
      this.state = "";
      this.country_id = null;
    },
    createFormEnable2() {
      this.country = "";
      this.createForm2 = true;
      this.updateForm2 = false;
    },

    createState() {
      if ((this.state == "", this.country_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/states`, {
          country_id: this.country_id,
          state_name: this.state,
        })
        .then((res) => {
          this.loading2 = false;
          this.success2 = true;
          this.state = "";
          this.createForm2 = true;
          this.updateForm2 = false;
          Swal.fire({
            title: "",
            text: "State added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getStates()
            .then((res) => {
              this.data2 = res.data;
              this.loading2 = false;
              res.data.forEach((element) => {
                this.statesOptions.push({
                  value: element.id,
                  text: element.state_name,
                });
              });
            })
            .catch(() => {
              this.loading2 = false;
            });
        })
        .catch(() => {
          this.loading2 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateState() {
      if ((this.state == "", this.country_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/states/${this.state_id}`, {
          country_id: this.country_id,
          state_name: this.state,
        })
        .then((res) => {
          this.loading2 = false;
          this.success2 = true;
          this.state = "";
          this.createForm2 = true;
          this.updateForm2 = false;
          Swal.fire({
            title: "",
            text: "State updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getStates()
            .then((res) => {
              this.data2 = res.data;
              this.loading2 = false;
              res.data.forEach((element) => {
                this.statesOptions.push({
                  value: element.id,
                  text: element.state_name,
                });
              });
            })
            .catch(() => {
              this.loading2 = false;
            });
        })
        .catch(() => {
          this.loading2 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStateStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/states/${item.id}`, {
          country_id: item.country_id,
          state_name: item.state_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading2 = false;
          this.success2 = true;
          this.state = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCities()
            .then((res) => {
              this.data3 = res.data;
              this.loading3 = false;
            })
            .catch(() => {
              this.loading3 = false;
            });
        })
        .catch(() => {
          this.loading2 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick2(item) {
      this.createForm2 = false;
      this.updateForm2 = true;
      this.state = item.state_name;
      this.state_id = item.id;
      this.country_id = item.country_id;
    },
    deleteButtonClick2(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/states/${item}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "State deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCities()
            .then((res) => {
              this.data3 = res.data;
              this.loading2 = false;
              this.dialog = false;
              this.deleteId = null;
              this.deleteType = null;
              this.deleting = false;
            })
            .catch(() => {
              this.loading3 = false;
            });
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading2 = false;
        });
    },

    // cities
    cancel3() {
      this.createForm3 = true;
      this.updateForm3 = false;
      this.city = "";
      this.state_id = null;
    },
    createFormEnable3() {
      this.city = "";
      this.createForm3 = true;
      this.updateForm3 = false;
    },

    createCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/cities`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading3 = false;
          this.success3 = true;
          this.city = "";
          this.createForm3 = true;
          this.updateForm3 = false;
          Swal.fire({
            title: "",
            text: "City added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCities()
            .then((res) => {
              this.data3 = res.data;
              this.loading3 = false;
            })
            .catch(() => {
              this.loading3 = false;
            });
        })
        .catch(() => {
          this.loading3 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/cities/${this.city_id}`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading3 = false;
          this.success3 = true;
          this.city = "";
          this.state_id = null;
          this.createForm3 = true;
          this.updateForm3 = false;
          Swal.fire({
            title: "",
            text: "City updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCities()
            .then((res) => {
              this.data3 = res.data;
              this.loading3 = false;
            })
            .catch(() => {
              this.loading3 = false;
            });
          this.cancel3();
        })
        .catch(() => {
          this.loading3 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeCityStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/cities/${item.id}`, {
          state_id: item.state_id,
          city_name: item.city_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading3 = false;
          this.success3 = true;
          this.city = "";
          this.state_id = null;
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch3();
          this.cancel3();
        })
        .catch(() => {
          this.loading3 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick3(item) {
      this.createForm3 = false;
      this.updateForm3 = true;
      this.city = item.city_name;
      this.city_id = item.id;
      this.state_id = item.state_id;
    },
    deleteButtonClick3(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/cities/${item}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "City deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          LocationService.getCities()
            .then((res) => {
              this.data3 = res.data;
              this.loading3 = false;
            })
            .catch(() => {
              this.loading3 = false;
            });
          this.loading3 = false;
          this.dialog = false;
          this.deleteId = null;
          this.deleteType = null;
          this.deleting = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading3 = false;
        });
    },
    cancel4() {
      this.createForm4 = true;
      this.updateForm4 = false;
      this.gender = "";
    },
    createFormEnable4() {
      this.gender = "";
      this.createForm4 = true;
      this.updateForm4 = false;
    },

    createGender() {
      if (this.gender == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      GenderService.addNewGender({
        gender_name: this.gender,
      })
        .then((res) => {
          console.log("anees", res.data);
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          Swal.fire({
            title: "",
            text: "Gender type added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch((error) => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateGender() {
      GenderService.editGender(
        {
          gender_name: this.gender,
        },
        this.gender_id
      )
        .then((res) => {
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          this.gender_id = "";
          Swal.fire({
            title: "",
            text: "Gender type updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch((error) => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeGanderStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/gender/${item.id}`, {
          gender_name: item.gender_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading4 = false;
          this.success4 = true;
          this.gender = "";
          this.gender_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel4();
        })
        .catch(() => {
          this.loading4 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick4(item) {
      this.createForm4 = false;
      this.updateForm4 = true;
      this.gender = item.gender_name;
      this.gender_id = item.id;
    },

    deleteButtonClick4(item) {
      this.deleting = true;
      GenderService.deleteGender(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Gender type deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
          this.deleteId = null;
          this.deleting = false;
          this.getInititalData();
          this.loading4 = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading4 = false;
        });
    },
    cancel5() {
      this.createForm5 = true;
      this.updateForm5 = false;
      this.industry = "";
    },
    createFormEnable5() {
      this.industry = "";
      this.createForm5 = true;
      this.updateForm5 = false;
    },

    createIndustry() {
      if (this.industry == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      IndustryService.addNewIndustry({
        industry_name: this.industry,
      })
        .then((res) => {
          this.loading5 = false;
          this.success5 = true;
          this.industry = "";
          Swal.fire({
            title: "",
            text: "Industry type added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel5();
        })
        .catch((error) => {
          this.loading5 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateIndustry() {
      IndustryService.editIndustry(
        {
          industry_name: this.industry,
        },
        this.industry_id
      )
        .then((res) => {
          this.loading5 = false;
          this.success5 = true;
          this.industry = "";
          this.industry_id = "";
          Swal.fire({
            title: "",
            text: "Industry type updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel5();
        })
        .catch((error) => {
          this.loading5 = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus5(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/industry/${item.id}`, {
          industry_name: item.industry_name,
          status: !item.status,
        })
        .then((res) => {
          this.loading5 = false;
          this.success5 = true;
          this.industry = "";
          this.industry_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel5();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick5(item) {
      this.createForm5 = false;
      this.updateForm5 = true;
      this.industry = item.industry_name;
      this.industry_id = item.id;
    },

    deleteButtonClick5(item) {
      this.deleting = true;
      IndustryService.deleteIndustry(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Industry type deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
          this.deleteId = null;
          this.deleting = false;
          this.getInititalData();
          this.loading5 = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    askUserAboutDelete(item, type) {
      this.deleteId = item.id;
      this.dialog = true;
      this.deleteType = type;
    },

    deleteItem() {
      this.deleting = true;
      if (this.deleteType == "country") {
        this.deleteButtonClick1(this.deleteId);
      }
      if (this.deleteType == "state") {
        this.deleteButtonClick2(this.deleteId);
      }
      if (this.deleteType == "city") {
        this.deleteButtonClick3(this.deleteId);
      }
      if (this.deleteType == "gender") {
        this.deleteButtonClick4(this.deleteId);
      }
      if (this.deleteType == "industry") {
        this.deleteButtonClick5(this.deleteId);
      }
    },
  },
};
</script>
