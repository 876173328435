import ApiInstance from './ApiInstance.js'

export default {
  
  getCountries() {
    return ApiInstance({ requiresAuth: true }).get('/countries');
  },
  
  getStates() {
    return ApiInstance({ requiresAuth: true }).get('/states');
  },
  
  getCities() {
    return ApiInstance({ requiresAuth: true }).get('/cities');
  },

  getGenders() {
    return ApiInstance({ requiresAuth: true }).get('/gender');
  },
}
